



















































import { Component, Prop } from 'vue-property-decorator';
import { ApiService, RoutingService } from '@/core/services';
import { BaseFormComponent, InputFieldCpt, ValidationSummaryCpt } from '@/core/components';
import { SetPasswordModel } from '@/core/webapi';

@Component({
  components: {
    InputFieldCpt,
    ValidationSummaryCpt,
  },
})
export default class CompleteRegistrationCpt extends BaseFormComponent {
  @Prop() userId: number = undefined as any;
  @Prop() token: string = undefined as any;

  isStatementAccepted: boolean | null = null;
  model = new SetPasswordModel({
    userId: 0,
    token: '',
    password: '',
    confirmPassword: '',
    verificationCode: this.isDevEnv ? '123456' : '',
    modelState: {},
  } as any);

  async created() {
    this.model.userId = this.userId;
    this.model.token = this.token;
    await this.requestRegistrationCode();
  }

  async requestRegistrationCode() {
    await ApiService.account().requestRegistrationCode({
      userId: this.userId,
      token: this.token,
    });

    this.$notify({
      title: this.$t('SMS Code sent') as string,
      message: this.$t('Please check your phone') as string,
      type: 'success',
    });
  }

  async onSubmitForm() {
    if (!this.isStatementAccepted) {
      this.isStatementAccepted = false;
      return;
    }

    const response = await ApiService.account().completeRegistration(this.model).handleValidationErrors(this.model);

    this.$auth.setToken(response);
    this.$eventHub.$emit('USER_UPDATED');

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('You are now logged in') as string,
      type: 'success',
    });

    this.$forceUpdate();

    this.$router.push(RoutingService.getDefaultRoute(this.authToken));
  }
}
