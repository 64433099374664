import { render, staticRenderFns } from "./CompleteRegistrationCpt.vue?vue&type=template&id=056791cc&scoped=true&"
import script from "./CompleteRegistrationCpt.vue?vue&type=script&lang=ts&"
export * from "./CompleteRegistrationCpt.vue?vue&type=script&lang=ts&"
import style0 from "./CompleteRegistrationCpt.vue?vue&type=style&index=0&id=056791cc&scoped=true&lang=scss&"
import style1 from "./CompleteRegistrationCpt.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056791cc",
  null
  
)

export default component.exports