


























import { Component, Vue } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { LoginCpt } from './components';

@Component({
  components: {
    LoginCpt,
  },
})
export default class LoginView extends AppVue {
  get helpEmail() {
    switch (process.env.VUE_APP_BRANDING) {
      case 'rcsi':
        return 'scrumstudy@rcsi.ie';
      default:
        return 'mike.hislop@worldrugby.org';
    }
  }
}
