




































































import { Component } from 'vue-property-decorator';
import QrcodeVue from 'qrcode.vue';

import { AccountService, ApiService, RoutingService } from '@/core/services';
import { BaseFormComponent, InputFieldCpt, ValidationSummaryCpt } from '@/core/components';
import { AuthenticatorModel, ConfirmAuthenticatorModel } from '@/core/webapi';

@Component({
  components: {
    QrcodeVue,
    InputFieldCpt,
    ValidationSummaryCpt,
  },
})
export default class AuthenticatorCpt extends BaseFormComponent {
  authenticator = new AuthenticatorModel();
  model = new ConfirmAuthenticatorModel({
    code: '',
    modelState: {},
  } as any);

  async created() {
    const enforce2Fa = JSON.parse(this.authToken.enforce2Fa);
    const is2FaEnabled = JSON.parse(this.authToken.is2FaEnabled);

    if (!enforce2Fa || is2FaEnabled) {
      this.$router.push(RoutingService.getDefaultRoute(this.authToken));
      return;
    }

    const loading = this.$loading({
      lock: true,
      text: `${this.$t('Getting data')}...`,
    });

    try {
      this.authenticator = (await ApiService.account().getAuthenticator()).data;
    } finally {
      loading.close();
    }
  }

  async onSubmitForm() {
    await ApiService.account().confirmAuthenticator(this.model).handleValidationErrors(this.model);
    await AccountService.refreshToken();

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Authenticator enabled') as string,
      type: 'success',
    });

    this.$router.push(RoutingService.getDefaultRoute(this.authToken));
  }
}
