




























import { Component } from 'vue-property-decorator';

import { ApiService } from '@/core/services';
import { ForgotPasswordModel } from '@/core/webapi';
import { BaseFormComponent, InputFieldCpt } from '@/core/components';

@Component({
  components: {
    InputFieldCpt,
  },
})
export default class ForgotPasswordCpt extends BaseFormComponent {
  model = new ForgotPasswordModel({
    email: '',
    modelState: {},
  } as any);

  async onSubmitForm() {
    await ApiService.account().forgotPassword(this.model).handleValidationErrors(this.model);

    this.$notify({
      title: this.$t('Email sent') as string,
      message: this.$t('Please check your inbox') as string,
      type: 'success',
    });

    this.$router.push('/login');
  }
}
