
























































import { Component } from 'vue-property-decorator';

import { Request2FaCodeStatus } from '@/core/models';
import { AccountService, ApiService, RoutingService } from '@/core/services';
import { BaseFormComponent, InputFieldCpt, ValidationSummaryCpt } from '@/core/components';
import { LocaleChangerSelectCpt } from '@/i18n';
import { LoginModel } from '@/core/webapi';

@Component({
  components: {
    InputFieldCpt,
    ValidationSummaryCpt,
    LocaleChangerSelectCpt,
  },
})
export default class LoginCpt extends BaseFormComponent {
  step = 1;
  request2FaCodeStatus = Request2FaCodeStatus.Uninitialized;

  model = new LoginModel({
    email: '',
    password: '',
    verificationCode: this.isDevEnv ? '123456' : '',
    modelState: {},
  } as any);

  get showResendCodeButton() {
    return this.request2FaCodeStatus !== Request2FaCodeStatus.UseAuthenticator;
  }

  get disableResendCodeButton() {
    return this.request2FaCodeStatus === Request2FaCodeStatus.Uninitialized;
  }

  async onSubmitForm() {
    this.step === 1 ? await this.requestLoginCode() : await this.login();
  }

  back() {
    this.step = 1;
    this.model.password = '';
    (this.model as any).verificationCode = '';
    (this.model as any).modelState = {};
  }

  async requestLoginCode() {
    const response = await ApiService.account().requestLoginCode(this.model).handleValidationErrors(this.model);

    this.request2FaCodeStatus = response.data as any;
    this.step = 2;
    this.focusVerificationCode();

    if (this.request2FaCodeStatus === Request2FaCodeStatus.SmsSent) {
      this.$notify({
        title: this.$t('SMS Code sent') as string,
        message: this.$t('Please check your phone') as string,
        type: 'success',
      });
    } else if (this.request2FaCodeStatus === Request2FaCodeStatus.UseAuthenticator) {
      this.$notify({
        title: this.$t('2FA Code required') as string,
        message: this.$t('Access authenticator app for code') as string,
        type: 'success',
      });
    }
  }

  focusVerificationCode() {
    this.$nextTick(() => {
      const refs = this.$refs.verificationCode as any;
      refs.$el.querySelector('input').focus();
    });
  }

  private async login() {
    await AccountService.login(this.model);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('You are now logged in') as string,
      type: 'success',
    });

    this.$router.push(RoutingService.getDefaultRoute(this.authToken));
  }
}
