













import { Component, Vue } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { ResetPasswordCpt } from './components';

@Component({
  components: {
    ResetPasswordCpt,
  },
})
export default class ResetPasswordView extends AppVue {
  get userId() {
    return this.getUrlParamAsNumber('userId');
  }

  get token() {
    return this.$route.params.token;
  }
}
