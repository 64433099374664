












import { Component, Vue } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { ForgotPasswordCpt } from './components';

@Component({
  components: {
    ForgotPasswordCpt,
  },
})
export default class ForgotPasswordView extends AppVue {}
