











































import { Component, Prop } from 'vue-property-decorator';

import { ApiService, RoutingService } from '@/core/services';
import { BaseFormComponent, InputFieldCpt, ValidationSummaryCpt } from '@/core/components';
import { SetPasswordCodeRequestModel, SetPasswordModel } from '@/core/webapi';
import { Request2FaCodeStatus } from '@/core/models';

@Component({
  components: {
    InputFieldCpt,
    ValidationSummaryCpt,
  },
})
export default class ResetPasswordCpt extends BaseFormComponent {
  @Prop() userId: number = undefined as any;
  @Prop() token: string = undefined as any;

  request2FaCodeStatus = Request2FaCodeStatus.Uninitialized;

  codeRequest = new SetPasswordCodeRequestModel({
    userId: 0,
    token: '',
    modelState: {},
  } as any);

  model = new SetPasswordModel({
    userId: 0,
    token: '',
    password: '',
    confirmPassword: '',
    verificationCode: this.isDevEnv ? '123456' : '',
    modelState: {},
  } as any);

  get showResendCodeButton() {
    return (
      this.request2FaCodeStatus !== Request2FaCodeStatus.Uninitialized &&
      this.request2FaCodeStatus !== Request2FaCodeStatus.UseAuthenticator
    );
  }

  get disableResendCodeButton() {
    return this.request2FaCodeStatus === Request2FaCodeStatus.Uninitialized;
  }

  async created() {
    this.codeRequest.userId = this.model.userId = this.userId;
    this.codeRequest.token = this.model.token = this.token;
    await this.requestPasswordResetCode();
  }

  async requestPasswordResetCode() {
    try {
      const response = await ApiService.account().requestPasswordResetCode(this.codeRequest);
      this.request2FaCodeStatus = response.data as any as Request2FaCodeStatus;
    } catch (error) {
      this.request2FaCodeStatus = Request2FaCodeStatus.Initialized;
    }

    if (this.request2FaCodeStatus === Request2FaCodeStatus.SmsSent) {
      this.$notify({
        title: this.$t('SMS Code sent') as string,
        message: this.$t('Please check your phone') as string,
        type: 'success',
      });
    } else if (this.request2FaCodeStatus === Request2FaCodeStatus.UseAuthenticator) {
      this.$notify({
        title: this.$t('2FA Code required') as string,
        message: this.$t('Access authenticator app for code') as string,
        type: 'success',
      });
    }
  }

  async onSubmitForm() {
    const response = await ApiService.account().resetPassword(this.model).handleValidationErrors(this.model);

    this.$auth.setToken(response.data);
    this.$eventHub.$emit('USER_UPDATED');

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('You are now logged in') as string,
      type: 'success',
    });

    this.$router.push(RoutingService.getDefaultRoute(this.authToken));
  }
}
